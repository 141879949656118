import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`ES Server - 1.7.1`}</h2>
    <p>{`The following is a guide on how to use `}<a parentName="p" {...{
        "href": "https://provision-portal.bddevops.com/"
      }}>{`Provision Portal`}</a>{` to deploy ES Server 1.7.1 software.`}</p>
    <br />
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p>{`Because we utilize BD Active Diretory via SSO to authenticate, `}<a parentName="p" {...{
          "href": "https://provision-portal.bddevops.com/"
        }}>{`Provision Portal`}</a>{` should `}<strong parentName="p">{`ONLY`}</strong>{` be used from a BD authorized Computer or Laptop and not on a shared device or VM.`}</p>
    </InlineNotification>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Minimum Requirements" mdxType="AccordionItem">
        <h4>{`Supported Operating System(s)`}</h4>
        <ul>
          <li parentName="ul">{`Windows Server 2016`}</li>
        </ul>
        <h4>{`Supported Software Versions`}</h4>
        <ul>
          <li parentName="ul">{`Microsoft SQL Server™ 2016`}</li>
          <li parentName="ul">{`Windows PowerShell™ 5`}</li>
        </ul>
        <h4>{`User Accounts`}</h4>
        <ul>
          <li parentName="ul">{`Admin Account (typically CFNAdmin)`}</li>
          <li parentName="ul">{`Service Account (typically CFNService)`}</li>
          <li parentName="ul">{`Optional if applicable: SQL User Account`}
            <ul parentName="li">
              <li parentName="ul">{`This account would need to be created if a Customer has set their Service Account to `}</li>
            </ul>
          </li>
        </ul>
        <h4>{`Execution Prep Steps`}</h4>
        <ul>
          <li parentName="ul">{`Turn off `}<inlineCode parentName="li">{`IE Enhanced Security Configuration`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Open `}<inlineCode parentName="li">{`Server Manager`}</inlineCode>{` and click on `}<inlineCode parentName="li">{`Local Server`}</inlineCode></li>
              <li parentName="ul">{`Find `}<inlineCode parentName="li">{`IE Enhanced Security Configuration`}</inlineCode>{` on the right upper section and click `}<inlineCode parentName="li">{`On`}</inlineCode>{` next to it`}</li>
              <li parentName="ul">{`In the pop up window, set both Administrators and Users to `}<inlineCode parentName="li">{`Off`}</inlineCode>{` and click `}<inlineCode parentName="li">{`Ok`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul">{`Download Google Chrome or Microsoft Edge browser - this is required to use `}<a parentName="li" {...{
              "href": "https://provision.bddevops.com"
            }}>{`BD Provisioning`}</a>
            <ul parentName="li">
              <li parentName="ul">{`Ensure `}<inlineCode parentName="li">{`IE Enhanced Security Configuration`}</inlineCode>{` is off`}</li>
              <li parentName="ul">{`Launch Internet Explorer`}</li>
              <li parentName="ul">{`Navigate to `}<inlineCode parentName="li">{`https://www.google.com/chrome/`}</inlineCode>{` or `}<inlineCode parentName="li">{`https://www.microsoft.com/en-us/edge`}</inlineCode>{` and download/install`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Ensure the Time Settings are correct for the VMs local time zone`}
            <ul parentName="li">
              <li parentName="ul">{`If the time is not within 5 minutes +/- of the local time, the BDAgent will fail to install`}</li>
            </ul>
          </li>
        </ul>
        <br />
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p><strong parentName="p">{`Note:`}</strong>{` The SQL User Account must utilize Windows Authentication and must have `}<inlineCode parentName="p">{`SysAdmin`}</inlineCode>{` access in the SQL instance during database deployment runtime. The SQL User can be the same as the Admin Account or an aditional account provided by the Customer with the proper access to SQL.`}</p>
        </InlineNotification>
      </AccordionItem>
      <AccordionItem title="Provision Portal Network Requirements" mdxType="AccordionItem">
        <ul>
          <li parentName="ul">{`Products must be able to communicate via secure TCP port 443 to all BD Provision Portal and AWS
Systems Manager servers.`}</li>
          <li parentName="ul">{`BD recommends that Customers use the Fully Qualified Domain Names (FQDN) rather than IP addresses when configuring firewall, proxy, and software rules to allow access to AWS services. The FQDN does not change; BD cannot guarantee that the IP addresses remains the same.`}</li>
          <li parentName="ul">{`Whitelisting must take place at firewall, web filtering applications and SSL proxies that might be in place.`}</li>
        </ul>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Destination Host Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Destination IP Address`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`provision.bddevops.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for customer access to download server agent bundles`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ssm.us-east-2.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software installation and configuration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ssm.us-east-1.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software installation and configuration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ssm.us-west-1.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software installation and configuration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ssm.us-west-2.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software installation and configuration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ssm-fips.us-east-2.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software installation and configuration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ssm-fips.us-east-1.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software installation and configuration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ssm-fips.us-west-1.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software installation and configuration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ssm-fips.us-west-2.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software installation and configuration`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`s3.us-west-1.amazonaws.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leveraged for device communication to perform software downloads`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`dl.bd.com`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Public facing Artifactory URL for BD package downloads`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`chocolatey.org`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variable`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Required to download the chocolatey installer executable`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`See `}<strong parentName="p">{`ES Server Deployment Guide`}</strong>{` for more information.`}</p>
      </AccordionItem>
      <AccordionItem title="ES Server Deployment Definitions" mdxType="AccordionItem">
        <h4>{`Deployment Types:`}</h4>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Clean (Fresh) Install`}</strong>{`: New installations for new customers or for Test Environments`}</li>
          <li parentName="ol"><strong parentName="li">{`Server Migration or Migration Upgrade`}</strong>{`: Existing customers that GCS will be migrating to a new Server OS and/or SQL Server Version. Databases will be upgraded.`}</li>
          <li parentName="ol"><strong parentName="li">{`Upgrade (Upgrade in Place)`}</strong>{`: Existing customers that GCS will deploy new versions of ES Software to an existing ES Server`}</li>
        </ol>
        <h4>{`Server Types:`}</h4>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Application Server`}</strong>{`: ES Pyxis Applications, Reporting Attachements, IIS Web Server`}</li>
          <li parentName="ol"><strong parentName="li">{`Database Server`}</strong>{`: SQL Server to host ES Application Databases`}</li>
          <li parentName="ol"><strong parentName="li">{`Integrated Solutions Server (ISS)`}</strong>{` - SQL Server with SSRS, IIS Web Server, ESR, HSV, IDM, MMS API`}</li>
          <li parentName="ol"><strong parentName="li">{`All In One Server (AIO)`}</strong>{`: Contains all of the above`}</li>
          <li parentName="ol"><strong parentName="li">{`DataSync Server`}</strong>{`: DataSync 1.0 Service only (Only used for Migrations)`}</li>
        </ol>
      </AccordionItem>
    </Accordion>
    <h2>{`Provision Portal`}</h2>
    <p>{`Welcome to Provision Portal for ES Release 1.7.1!`}</p>
    <p>{`This guide will walk you through how to deploy the ES Pyxis Server Environment from start to finish.`}</p>
    <h2>{`Deployment Glossary`}</h2>
    <Tabs mdxType="Tabs">
      <Tab label="Components" mdxType="Tab">
        <h4>{`Component Types`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Server Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`All In One`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`All ES application deploy to this VM`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Application`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Application Server for ES Pyxis applications & Web Page`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Integrated Solutions`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Reporting Server with SQL SSRS, HSV, IDM`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Database`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL Server Host`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`DataSync`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DataSync (DS)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DataSync “1.0” Server`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="Workflows" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Workflow`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.1 - AIO Clean Install`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a clean (fresh) AIO Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.1 - 3 Box Clean Install`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP/DB/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a clean multi-server environment. This should be used to deploy App and ISS only, if a Customer manages their own Database Server.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.1 - AIO Upgrade`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade an existing 1.7.0 AIO deployed with Provision Portal`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.1 - 3 Box Upgrade`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP/DB/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade an existing 1.7.0, 3 Box environment deployed with Provision Portal. If a Customer manages their own Database Server, this should be used to upgrade App and ISS only.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.1 - AIO Migration`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a clean (fresh) AIO Server and migrate the Databases for upgrade.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Server 1.7.1 - 3 Box Migration`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP/DB/ISS/DS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy an a clean (fresh) 3 Box environment and migrate the Databases for upgrade. This Workflow can deploy Application and ISS Servers only, if a Customer manages their own Database Server. It can also deploy DataSync Servers as part of the full environment.`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="Workflow Steps" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Step`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Validate Credentials`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP/DB/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Verifies Service Account credentials are valid`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy AIO Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy the AIO Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Database Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy the Database (SQL) Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Application Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy the Application Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Integrated Solutions Server Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy the Integrated Solutions Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy DataSync Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DataSync`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy a DataSync 1.0 standalone Server (Only used for Migrations)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Application Server Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy fresh DSServerOLTP, Scheduling Services, DSRF, CFUpdateService`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Integrated Solutions Server Databases Server`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy fresh DSServerReports, HSV, IDM Databases`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Stop BD Services`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Attempts to stop and disable running BD Services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Stop IS Server BD Services and ETL`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Attempts to stop and disable ETL and running BD Services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Backup Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`MANUAL STEP:`}</strong>{` Backup all production databses after all services are stopped`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Restore Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/DB`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`MANUAL STEP:`}</strong>{` Restore all production databses after all services are stopped`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO Server Upgrade`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade the AIO Server software`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Application Server Upgrade`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade the Application Server software`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Integrated Solutions Server Upgrade`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade the ISS Server software`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade DSServerOLTP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute the DSServerOLTP upgrade scripts`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade Application Server Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute the DSRF, Scheduling Services upgrade scripts`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start Web Applications`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start IIS, Start PES and application pools, configure IDS`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start DataSync Service`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP/DataSync`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start DataSync Services (1.0 and 2.0)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start Active Directory Sync Service`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start Active Directory Sync Service`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start External Messaging Service`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start External Messaging Service`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start and Configure Application Server Services`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start BD Services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Start and Configure ISS Services`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Start BD Services, IIS, configure IDM`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Upgrade ISS Server Databases`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Execute the DSServerReports, HSV, IDM upgrade scripts`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Update DataSync Values`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Updates the Core DataSync Server information in DSServerOLTP when migrating to a new system`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="Execute Actions" mdxType="Tab">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Step`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Component`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ConfirmCredentials`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP/DB/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Verifies Service Account credentials are valid`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ES-App-StartServices`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Changes BD Services Startup Type to `}<inlineCode parentName="td">{`Automatic`}</inlineCode>{` and starts BD services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ES-ISS-StartServices`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Changes BD Services Startup Type to `}<inlineCode parentName="td">{`Automatic`}</inlineCode>{`, starts BD services, starts IIS, executes IDM Config Chocopack`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ES-ISS-StopServices`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Attempts to stop and disable ETL and running BD Services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ES-Migration-GetDataSyncServers`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Queries Source DSServerOLTP for existing DataSync servers`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ES-Migration-UpdateDataSync`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Updates the Address values for the new DataSync Server(s)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ES-Migration-StopServices`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/APP`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Attempts to stop and disable running BD Services`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ESR-Clean-DSServerReports`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploys a fresh, “clean”, DSServerReports Database. Used to fix ESR issues ONLY!`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BD-ESR-Reports`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AIO/ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploys the ESR choco pack. Used to fix ESR issues ONLY! Current ESR installation must be removed from the system`}</td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Warning:`}</strong>{` Ensure the Customer has issued the correct Windows Server 2016 Virtual Machine, connected to a Domain with the proper user accounts created. If a Customer provides their own SQL Server, verify you are able to connect with the provided account credentials before proceeding.`}</p>
    </InlineNotification>
    <h2>{`Choose Your Deployment Path`}</h2>
    <Tabs mdxType="Tabs">
      <Tab label="Clean Install" mdxType="Tab">
        <InlineNotification mdxType="InlineNotification">
          <p>{`Clean installations require a new Virtual Machine for each: Application Server, Integrated Solutions Server, and Database Server (if applicable) OR a new All in One (AIO) Server`}</p>
        </InlineNotification>
        <h3>{`Creating the Deployment Profile`}</h3>
        <ol>
          <li parentName="ol">{`Navigate to `}<a parentName="li" {...{
              "href": "https://provision-portal.bddevops.com/"
            }}>{`Provision Portal`}</a>{` on your local web browser`}
            <ul parentName="li">
              <li parentName="ul">{`Internet Explorer is not supported`}</li>
            </ul>
          </li>
          <li parentName="ol">{`Login with your BD Global ID Credentials`}</li>
          <li parentName="ol">{`Under the `}<inlineCode parentName="li">{`Customers`}</inlineCode>{` section on the left hand side, navigate to or search for the correct `}<strong parentName="li">{`Customer Account`}</strong></li>
          <li parentName="ol">{`Click on the Account to be taken to its specific Deployment management page`}</li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Deployment Profiles`}</inlineCode></li>
          <li parentName="ol">{`Assuming this is a new deployment, click on `}<inlineCode parentName="li">{`Add Profile`}</inlineCode></li>
          <li parentName="ol">{`For the `}<inlineCode parentName="li">{`Profile Display Name`}</inlineCode>{`, name the profile according to the System Release and Server Deployment Type`}
            <ul parentName="li">
              <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`ES Server 1.7.1 - 3 Box Clean Install`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.1 - AIO Clean Install`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.1 - 3 Box Clean Install`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`+ CREATE`}</inlineCode></li>
          <li parentName="ol">{`Enter the Parameters to be used for the Deployment - `}<a parentName="li" {...{
              "href": "#deployment-reference"
            }}>{`Deployment Reference`}</a></li>
          <li parentName="ol">{`After the Parameters are set, the Profile will automatically be saved to be used for the Server Deployment`}</li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`There is no need to `}<em parentName="p">{`Save`}</em>{` as Provision Portal saves upon entry, also making editing parameters easier`}</p>
        </InlineNotification>
        <h3>{`Creating the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Server Deployments`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`New Deployment`}</inlineCode>{` to create a New Deployment`}</li>
          <li parentName="ol">{`Input a descriptive, relevant `}<inlineCode parentName="li">{`Deployment Name`}</inlineCode>{` and click `}<inlineCode parentName="li">{`Next`}</inlineCode>
            <ol parentName="li">
              <li parentName="ol">{`Example: `}<inlineCode parentName="li">{`ES Server 1.7.1 - 3 Box Clean Install`}</inlineCode></li>
            </ol>
          </li>
          <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`ES Server 1.7.1`}</inlineCode>{` box`}</li>
          <li parentName="ol">{`Set the Quantity for the applicable `}<a parentName="li" {...{
              "href": "#deployment-glossary"
            }}>{`Component(s)`}</a></li>
        </ol>
        <br />
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p><strong parentName="p">{`Note:`}</strong>{` `}<em parentName="p">{`There can only be a MAXIMUM 1 APP, 1 DB, 1 ISS for multibox deployment.`}</em></p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 6
        }}>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Create`}</inlineCode>{` and you should see a `}<inlineCode parentName="li">{`Successfully created deployment`}</inlineCode>{` banner at the top of the page`}</li>
          <li parentName="ol">{`Copy and take note of the 8 digit Deployment Code at the top of the page (`}<em parentName="li">{`You will need this later`}</em>{`)`}
            <ul parentName="li">
              <li parentName="ul">{`CUSTOMERS / 123456 / DEPLOYMENTS / `}<strong parentName="li">{`ABCDEFGH`}</strong></li>
            </ul>
          </li>
          <li parentName="ol">{`If you haven’t already, login to the VMs to verify all requirements are met`}</li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p><strong parentName="p">{`Warning:`}</strong>{` If the system requirements are not met, do not proceed. If the Customer pre installed SQL, ensure it has the correct features including Reporting Services.`}</p>
        </InlineNotification>
        <h3>{`Generating the BDAgent Installer`}</h3>
        <ol>
          <li parentName="ol">{`On one of the VMs to be deployed, navigate to the `}<a parentName="li" {...{
              "href": "https://provision.bddevops.com"
            }}>{`BD Provision Site`}</a>
            <ul parentName="li">
              <li parentName="ul">{`Must use Google Chrome or Microsoft Edge`}</li>
            </ul>
          </li>
          <li parentName="ol"><em parentName="li">{`Step 1`}</em>{`: `}
            <ul parentName="li">
              <li parentName="ul">{`Enter the `}<inlineCode parentName="li">{`Customer ID`}</inlineCode></li>
              <li parentName="ul">{`Enter the `}<inlineCode parentName="li">{`Deployment Code`}</inlineCode>{` from Step 18`}</li>
            </ul>
          </li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Submit`}</inlineCode></li>
          <li parentName="ol"><em parentName="li">{`Step 2`}</em>{`: Verify the information is correct`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Submit`}</inlineCode></li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`Clicking `}<inlineCode parentName="p">{`Submit`}</inlineCode>{` sends a command to Amazon Web Services (AWS) to create a customized installer for AWS Systems Manager.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 26
        }}>
          <li parentName="ol"><em parentName="li">{`Step 3`}</em>{`: Click on `}<inlineCode parentName="li">{`Download`}</inlineCode></li>
          <li parentName="ol">{`Once the `}<inlineCode parentName="li">{`BDAgent`}</inlineCode>{` installer is downloaded, copy the file to each of the target VMs`}</li>
          <li parentName="ol">{`Verify the time settings are correct for the timezone of the VM(s).`}</li>
          <li parentName="ol">{`In the folder location of the `}<inlineCode parentName="li">{`BDAgent`}</inlineCode>{` installer, press and hold `}<inlineCode parentName="li">{`SHIFT`}</inlineCode>{` and `}<inlineCode parentName="li">{`Right click`}</inlineCode>{` on your mouse. Click on `}<inlineCode parentName="li">{`Open PowerShell (or CMD) window here`}</inlineCode>{`. Type the name of the installer `}<inlineCode parentName="li">{`BDAgent`}</inlineCode>{`, hit `}<inlineCode parentName="li">{`TAB`}</inlineCode>{`,then hit `}<inlineCode parentName="li">{`ENTER`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`After the Amazon SSM Agent is installed, verify the `}<inlineCode parentName="li">{`Amazon SSM Agent`}</inlineCode>{` is running in Service Manager`}</li>
          <li parentName="ol">{`Navigate back to Provision Portal to the Deployment`}</li>
        </ol>
        <h3>{`Executing the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`When the Amazon SSM Agent registers the VM with AWS, the `}<inlineCode parentName="li">{`Status`}</inlineCode>{` will read `}<inlineCode parentName="li">{`Online`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`This can take up to 5 minutes to happen`}</em></li>
            </ul>
          </li>
          <li parentName="ol">{`If 3 Box, assign the `}<inlineCode parentName="li">{`Components`}</inlineCode>{` with the correct VM hostnames`}</li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`SAVE COMPONENT ASSIGNMENTS`}</inlineCode></li>
          <li parentName="ol">{`When everyone is ready to start the execution, click on `}<inlineCode parentName="li">{`RUN WORKFLOW`}</inlineCode></li>
          <li parentName="ol">{`The `}<inlineCode parentName="li">{`Execute Workflow`}</inlineCode>{` window should pop up`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{` from the dropdown`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`, the one you created in Steps 4-11`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Run`}</inlineCode></li>
          <li parentName="ol">{`The first Step to `}<inlineCode parentName="li">{`Validate Credentials`}</inlineCode>{` will execute automatically`}
            <ol parentName="li">
              <li parentName="ol">{`If this step fails, Service User account credentials were not entered correctly in the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`. Please update the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` and then return to the Deployment. Click `}<inlineCode parentName="li">{`Run Workflow`}</inlineCode>{` to load the newly updated `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` for the Deployment.`}</li>
            </ol>
          </li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`Each Deployment Step is separated out with PLAY button (`}<inlineCode parentName="p">{`Run Step`}</inlineCode>{` button).
`}<strong parentName="p">{`Each Step is meant to run in order, only when the previous step reports`}</strong>{` `}<inlineCode parentName="p">{`Success`}</inlineCode>{`.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 9
        }}>
          <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` button on the next step, `}<inlineCode parentName="li">{`Deploy Database Server`}</inlineCode>{`, in the list`}</li>
          <li parentName="ol">{`Once the Step reports `}<inlineCode parentName="li">{`Success`}</inlineCode>{`, click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` on the next step down in the list and follow the rest of the Deployment Workflow in order`}</li>
          <li parentName="ol">{`Once the last Step is complete, ES Server is deployed`}</li>
          <li parentName="ol">{`Continue on to `}<a parentName="li" {...{
              "href": "#post-installation-configuration"
            }}>{`Post Installation Configuration`}</a>{` Steps`}</li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p>{`User has the ability to `}<inlineCode parentName="p">{`CANCEL`}</inlineCode>{` a Step Execution. This should be used `}<strong parentName="p">{`ONLY`}</strong>{` to stop a Step if an error was made. This action “hard stops” the execution and does not have the ability to safely exit (It is likely you can replay the Step when ready to proceed)`}</p>
        </InlineNotification>
        <br />
      </Tab>
      <Tab label="Upgrade" mdxType="Tab">
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`Upgrades require an existing Application Server, Integrated Solutions Server, and/or Database Server (if applicable) OR an AIO Server, `}<strong parentName="p">{`that was deployed using Provision Portal`}</strong></p>
        </InlineNotification>
        <h3>{`Creating the Deployment Profile`}</h3>
        <ol>
          <li parentName="ol">{`Navigate to `}<a parentName="li" {...{
              "href": "https://provision-portal.bddevops.com/"
            }}>{`Provision Portal`}</a>{` on your local web browser`}
            <ul parentName="li">
              <li parentName="ul">{`Internet Explorer is not supported`}</li>
            </ul>
          </li>
          <li parentName="ol">{`Login with your BD Global ID Credentials`}</li>
          <li parentName="ol">{`Under the `}<inlineCode parentName="li">{`Customers`}</inlineCode>{` section on the left hand side, navigate to or search for the correct `}<strong parentName="li">{`Customer Account`}</strong></li>
          <li parentName="ol">{`Click on the Account to be taken to its specific Deployment management page`}</li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Deployment Profiles`}</inlineCode></li>
          <li parentName="ol">{`Assuming this is a new deployment, click on `}<inlineCode parentName="li">{`Add Profile`}</inlineCode></li>
          <li parentName="ol">{`For the `}<inlineCode parentName="li">{`Profile Display Name`}</inlineCode>{`, name the profile according to the System Release and Server Deployment Type`}
            <ul parentName="li">
              <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`ES Server 1.7.1 - 3 Box Upgrade`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.1 - AIO Upgrade`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.1 - 3 Box Upgrade`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`+ CREATE`}</inlineCode></li>
          <li parentName="ol">{`Enter the Parameters to be used for the Deployment - `}<a parentName="li" {...{
              "href": "#deployment-reference"
            }}>{`Deployment Reference`}</a>
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Pro Tip: Copy and Paste as much information as you can from the Imp Form and from the Customer VM(s)`}</em></li>
            </ul>
          </li>
          <li parentName="ol">{`After the Parameters are set, the Profile will automatically be saved to be used for the Server Deployment`}</li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`There is no need to `}<em parentName="p">{`Save`}</em>{` as Provision Portal saves upon entry, also making editing parameters easier`}</p>
        </InlineNotification>
        <h3>{`Executing the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Server Deployments`}</inlineCode></li>
          <li parentName="ol">{`Find the Deployment Name in the list, that you are upgrading (remember this is an existing, already deployed server on ES 1.7.0)`}</li>
          <li parentName="ol">{`Login to the existing deployed VM(s) and ensure the Amazon SSM Agent service is running before proceeding`}</li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p><strong parentName="p">{`Warning`}</strong>{`: `}<strong parentName="p">{`THIS IS SITE DOWNTIME`}</strong>{` At this point, the Customer will need to be notified and cofirm they are ready for the ES Servers and MedStations to go offline.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 4
        }}>
          <li parentName="ol">{`When ready to proceed with the upgrade, click on `}<inlineCode parentName="li">{`Run Workflow`}</inlineCode>{` at the top`}
            <ul parentName="li">
              <li parentName="ul">{`The Deployment will still show as `}<inlineCode parentName="li">{`ES Server 1.7`}</inlineCode>{`. This is expected behavior.`}</li>
            </ul>
          </li>
          <li parentName="ol">{`The `}<inlineCode parentName="li">{`Execute Workflow`}</inlineCode>{` window will pop up`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.1 - AIO Upgrade`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.1 - 3 Box Upgrade`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Select the Profile you created in Steps 4-11 in the above section`}</li>
          <li parentName="ol">{`When you click `}<inlineCode parentName="li">{`RUN`}</inlineCode>{` the first Step: `}<inlineCode parentName="li">{`Validate Credentials`}</inlineCode>{` will automatically execute`}
            <ol parentName="li">
              <li parentName="ol">{`If this step fails, Service User account credentials were not entered correctly in the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`. Please update the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` and then return to the Deployment. Click `}<inlineCode parentName="li">{`Run Workflow`}</inlineCode>{` to load the newly updated `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` for the Deployment.`}</li>
            </ol>
          </li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`Each Deployment Step is separated out with PLAY button (`}<inlineCode parentName="p">{`Run Step`}</inlineCode>{` button).
`}<strong parentName="p">{`Each Step is meant to run in order, only when the previous step reports`}</strong>{` `}<inlineCode parentName="p">{`Success`}</inlineCode>{`.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 9
        }}>
          <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` button on the `}<inlineCode parentName="li">{`Stop BD Services`}</inlineCode>{` Step`}
            <ul parentName="li">
              <li parentName="ul">{`Ensure Services are stopped on the target VM (AIO or APP Server)`}</li>
            </ul>
          </li>
          <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` button on the `}<inlineCode parentName="li">{`Stop IS Server BD Services and ETL`}</inlineCode>{` Step`}
            <ul parentName="li">
              <li parentName="ul">{`Ensure ETL and Services are stopped on the target VM (AIO or ISS Server) `}</li>
            </ul>
          </li>
          <li parentName="ol">{`Once the services are stopped, click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` button on the next Workflow Step to backup databases. This is a manual step.`}</li>
          <li parentName="ol">{`Continue to upgrade the Servers and Databases by running each step, in order. `}</li>
          <li parentName="ol">{`Once the last Step is complete, ES Server is deployed.`}</li>
          <li parentName="ol">{`Continue on to `}<a parentName="li" {...{
              "href": "#post-installation-configuration"
            }}>{`Post Installation Configuration`}</a>{` Steps`}</li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p>{`User has the ability to `}<inlineCode parentName="p">{`CANCEL`}</inlineCode>{` a Step Execution. This should be used `}<strong parentName="p">{`ONLY`}</strong>{` to stop a Step if an error was made. This action “hard stops” the execution and does not have the ability to safely exit (It is likely you can replay the Step when ready to proceed)`}</p>
        </InlineNotification>
      </Tab>
      <Tab label="Server Migrations" mdxType="Tab">
        <InlineNotification mdxType="InlineNotification">
          <p>{`Server Migrations require a new Application Server, Integrated Solutions Server, and Database Server (if applicable) OR a new AIO Server. Server Migration is designed to allow the user to deploy the Server Software ahead of downtime and then execute the Database Migration and Database Upgrades during planned downtime`}</p>
        </InlineNotification>
        <h3>{`Creating the Deployment Profile`}</h3>
        <ol>
          <li parentName="ol">{`Navigate to `}<a parentName="li" {...{
              "href": "https://provision-portal.bddevops.com/"
            }}>{`Provision Portal`}</a>{` on your local web browser`}
            <ul parentName="li">
              <li parentName="ul">{`Internet Explorer is not supported`}</li>
            </ul>
          </li>
          <li parentName="ol">{`Login with your BD Global ID Credentials`}</li>
          <li parentName="ol">{`Under the `}<inlineCode parentName="li">{`Customers`}</inlineCode>{` section on the left hand side, navigate to or search for the correct `}<strong parentName="li">{`Customer Account`}</strong></li>
          <li parentName="ol">{`Click on the Account to be taken to its specific Deployment management page`}</li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Deployment Profiles`}</inlineCode></li>
          <li parentName="ol">{`Assuming this is a new deployment, click on `}<inlineCode parentName="li">{`Add Profile`}</inlineCode></li>
          <li parentName="ol">{`For the `}<inlineCode parentName="li">{`Profile Display Name`}</inlineCode>{`, name the profile according to the System Release and Server Deployment Type`}
            <ul parentName="li">
              <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`ES Server 1.7.1 - 3 Box Migration`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.1 - AIO Migration`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`ES Server 1.7.1 - 3 Box Migration`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`+ CREATE`}</inlineCode></li>
          <li parentName="ol">{`Enter the Parameters to be used for the Deployment - `}<a parentName="li" {...{
              "href": "#deployment-reference"
            }}>{`Deployment Reference`}</a>
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Pro Tip: Copy and Paste as much information as you can from the Imp Form and from the Customer VM(s)`}</em></li>
            </ul>
          </li>
          <li parentName="ol">{`After the Parameters are set, the Profile will automatically be saved to be used for the Server Deployment`}</li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`There is no need to `}<em parentName="p">{`Save`}</em>{` as Provision Portal saves upon entry, also making editing parameters easier`}</p>
        </InlineNotification>
        <h3>{`Creating the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Server Deployments`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`New Deployment`}</inlineCode>{` to create a New Deployment`}</li>
          <li parentName="ol">{`Input a descriptive, relevant `}<inlineCode parentName="li">{`Deployment Name`}</inlineCode>{` and click `}<inlineCode parentName="li">{`Next`}</inlineCode></li>
          <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`ES Server 1.7.1`}</inlineCode>{` box`}</li>
          <li parentName="ol">{`Set the Quantity for the applicable Component(s)`}</li>
        </ol>
        <br />
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p><strong parentName="p">{`Note:`}</strong>{` There can only be MAXIMUM 1 APP, 1 DB, 1 ISS for multibox deployment.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 6
        }}>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Create`}</inlineCode>{` and you should see a `}<inlineCode parentName="li">{`Successfully created deployment`}</inlineCode>{` banner at the top of the page`}</li>
          <li parentName="ol">{`Copy and take note of the 8 digit Deployment Code at the top of the page (`}<em parentName="li">{`You will need this later`}</em>{`)`}
            <ul parentName="li">
              <li parentName="ul">{`CUSTOMERS / 123456 / DEPLOYMENTS / `}<strong parentName="li">{`ABCDEFGH`}</strong></li>
            </ul>
          </li>
          <li parentName="ol">{`If you haven’t already, login to the VMs to verify all requirements are met`}</li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p><strong parentName="p">{`Warning:`}</strong>{` If the system requirements are not met, do not proceed. If the Customer installed SQL, ensure it has the correct features including Reporting Services.`}</p>
        </InlineNotification>
        <h3>{`Generating the BDAgent Installer`}</h3>
        <ol>
          <li parentName="ol">{`On one of the VMs to be deployed, navigate to `}<a parentName="li" {...{
              "href": "https://provision.bddevops.com"
            }}>{`BD Provision Site`}</a>
            <ul parentName="li">
              <li parentName="ul">{`Must use Google Chrome or Microsoft Edge`}</li>
            </ul>
          </li>
          <li parentName="ol"><em parentName="li">{`Step 1`}</em>{`: `}
            <ul parentName="li">
              <li parentName="ul">{`Enter the `}<inlineCode parentName="li">{`Customer ID`}</inlineCode></li>
              <li parentName="ul">{`Enter the `}<inlineCode parentName="li">{`Deployment Code`}</inlineCode>{` from Step 18`}</li>
            </ul>
          </li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Submit`}</inlineCode></li>
          <li parentName="ol"><em parentName="li">{`Step 2`}</em>{`: Verify the information is correct`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Submit`}</inlineCode></li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`Clicking `}<inlineCode parentName="p">{`Submit`}</inlineCode>{` sends a command to Amazon Web Services (AWS) to create a customized installer for AWS Systems Manager.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 6
        }}>
          <li parentName="ol"><em parentName="li">{`Step 3`}</em>{`: Click on `}<inlineCode parentName="li">{`Download`}</inlineCode></li>
          <li parentName="ol">{`Once the `}<inlineCode parentName="li">{`BDAgent`}</inlineCode>{` installer is downloaded, copy the file to each of the target VMs`}</li>
          <li parentName="ol">{`Verify the time settings are correct for the timezone of the VM(s)`}</li>
          <li parentName="ol">{`In the folder location of the `}<inlineCode parentName="li">{`BDAgent`}</inlineCode>{` installer, press and hold `}<inlineCode parentName="li">{`SHIFT`}</inlineCode>{` and `}<inlineCode parentName="li">{`Right click`}</inlineCode>{` on your mouse. Click on `}<inlineCode parentName="li">{`Open PowerShell (or CMD) window here`}</inlineCode>{`. Type the name of the installer `}<inlineCode parentName="li">{`BDAgent`}</inlineCode>{`, hit `}<inlineCode parentName="li">{`TAB`}</inlineCode>{`,then hit `}<inlineCode parentName="li">{`ENTER`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`After the Amazon SSM Agent is installed, verify the `}<inlineCode parentName="li">{`Amazon SSM Agent`}</inlineCode>{` is running in Service Manager`}</li>
          <li parentName="ol">{`Navigate back to Provision Portal to the Deployment`}</li>
        </ol>
        <h3>{`Executing the Deployment`}</h3>
        <ol>
          <li parentName="ol">{`When the Amazon SSM Agent registers the VM with AWS, the `}<inlineCode parentName="li">{`Status`}</inlineCode>{` will read `}<inlineCode parentName="li">{`Online`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`This can take up to 5 minutes to happen`}</em></li>
            </ul>
          </li>
          <li parentName="ol">{`If 3 Box, assign the `}<inlineCode parentName="li">{`Component`}</inlineCode>{` with the correct VM hostname`}</li>
          <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`SAVE COMPONENT ASSIGNMENTS`}</inlineCode></li>
          <li parentName="ol">{`When everyone is ready for deployment time, click on `}<inlineCode parentName="li">{`RUN WORKFLOW`}</inlineCode></li>
          <li parentName="ol">{`The `}<inlineCode parentName="li">{`Execute Workflow`}</inlineCode>{` window should pop up`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{` from the dropdown`}</li>
          <li parentName="ol">{`Select the correct `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`, the one you created in Steps 4-11`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Run`}</inlineCode></li>
          <li parentName="ol">{`The first Step to `}<inlineCode parentName="li">{`Validate Credentials`}</inlineCode>{` will execute automatically`}
            <ol parentName="li">
              <li parentName="ol">{`If this step fails, Service User account credentials were not entered correctly in the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{`. Please update the `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` and then return to the Deployment. Click `}<inlineCode parentName="li">{`Run Workflow`}</inlineCode>{` to load the newly updated `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` for the Deployment.`}</li>
            </ol>
          </li>
        </ol>
        <br />
        <InlineNotification mdxType="InlineNotification">
          <p>{`Each Deployment Step is separated out with PLAY button (`}<inlineCode parentName="p">{`Run Step`}</inlineCode>{` button).
`}<strong parentName="p">{`Each Step is meant to run in order, only when the previous step reports`}</strong>{` `}<inlineCode parentName="p">{`Success`}</inlineCode>{`.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 10
        }}>
          <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Run Step`}</inlineCode>{` button on the next Steps to Deploy the Server`}
            <ul parentName="li">
              <li parentName="ul">{`You can deploy multiple Servers in parallel at this point if you choose`}</li>
              <li parentName="ul">{`The Step `}<inlineCode parentName="li">{`Deploy DataSync Server`}</inlineCode>{` can be skipped if it’s not appicable  `}</li>
            </ul>
          </li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p><strong parentName="p">{`Warning`}</strong>{`: `}<strong parentName="p">{`THIS IS SITE DOWNTIME`}</strong>{` At this point, the Customer will need to be notified and cofirm they are ready for the ES Servers and MedStations to go offline.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 11
        }}>
          <li parentName="ol">{`Once the Customer gives the “OK” for downtime, proceed to the `}<inlineCode parentName="li">{`Update DataSync Values`}</inlineCode>{` Step. Verify the New Sync values are in the Source DSServerOLTP database. This will update the CORE Sync Server only.`}</li>
          <li parentName="ol">{`In order to update multiple DataSync servers, please click the three dots next to the Application Server and click `}<inlineCode parentName="li">{`ExecuteAction`}</inlineCode></li>
          <li parentName="ol">{`You will need to exeucte the following action for `}<em parentName="li">{`EACH`}</em>{` non-Core Sync Server`}</li>
          <li parentName="ol">{`From the dropdown select `}<inlineCode parentName="li">{`BD-ES-Migration-GetDataSyncServers`}</inlineCode>{`, fill out the parameters and hit `}<inlineCode parentName="li">{`Execute`}</inlineCode>{`. Repeat if necessary with the other DataSync Servers.`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-sql"
          }}>{`USE DSSERVEROLTP SELECT * FROM Core.Server
`}</code></pre>
        <br />
        <ol {...{
          "start": 15
        }}>
          <li parentName="ol">{`Continue on to Steps to Stop BD Services. Verify all BD Services and ETL are stopped and disabled before proceeding`}</li>
          <li parentName="ol">{`The next two Steps to `}<inlineCode parentName="li">{`Backup Databases`}</inlineCode>{` and `}<inlineCode parentName="li">{`Restore Databases`}</inlineCode>{` are manual. You `}<strong parentName="li">{`MUST`}</strong>{` backup databases, copy them to the new SQL server, and restore them`}</li>
          <li parentName="ol">{`Continue on to Upgrade the Databases and finish up the rest of the Workflow`}</li>
          <li parentName="ol">{`Once complete, move on to the `}<a parentName="li" {...{
              "href": "#post-installation-configuration"
            }}>{`Post Installation Configuration`}</a>{` Steps`}</li>
        </ol>
        <br />
        <InlineNotification kind="error" mdxType="InlineNotification">
          <p>{`User has the ability to `}<inlineCode parentName="p">{`CANCEL`}</inlineCode>{` a Step Execution. This should be used `}<strong parentName="p">{`ONLY`}</strong>{` to stop a Step if an error was made. This action “hard stops” the execution and does not have the ability to safely exit (It is likely you can replay the Step when ready to proceed)`}</p>
        </InlineNotification>
      </Tab>
    </Tabs>
    <h2>{`Deployment Reference`}</h2>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Parameter Reference" mdxType="AccordionItem">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Expected Format`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Required?`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Service User Account Name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`domian\\username`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Service User Account username to run ES Applications, Web AppPools, and is added to SQL Server`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Service User Account Password `}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`password`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Service User Account password provided by the Customer`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL User (Username Only)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`username`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL User Account name. This could be the Admin user account (CFNAdmin) or an additional account that has proper SQL access`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL Password`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`password`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SQL User Account password provided by the Customer`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Database Server Name, SQL Port`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`server-name`}</inlineCode>{` or `}<inlineCode parentName="td">{`server-name.domain.com`}</inlineCode>{` or `}<inlineCode parentName="td">{`server-name, 1111`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Database server name either as Hostname or FQDN. Port number can be specified with a `}<inlineCode parentName="td">{`,`}</inlineCode>{` then port number. Named Instance is specified as `}<inlineCode parentName="td">{`server-name\\instance-name`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`CCE Messages Path `}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<DriveLetter>:\\CCEMessages`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Folder path to store CCE Messages`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ES Archive File Path`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<DriveLetter>:\\ES_Archiving`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Folder path to store Archive files`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS API Authentication?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`False`}</inlineCode>{` or `}<inlineCode parentName="td">{`True`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Signifies if MMS API Authenticaion is to be used`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS API Authentication Secret`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<SecretKey>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS API authentication secret`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS API Authentication Audience`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Audience`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS API audience`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS API Authentication Issuer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Issuer`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS API Authenticaion Issuer`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS Inventory Frequency`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<Integer>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS inventory frequency in minutes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS Inventory KP Authentication Secret`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`NO`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`KP authenticaion secret`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`MMS Inventory Server ID (Customer ID)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`12345678`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Customer Site ID`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`IDS URL`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`https://<AppServerFQDN>:11998/`}</inlineCode>{` or `}<inlineCode parentName="td">{`https://<AIOFQDN>:11998/`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`URL to IDS instance. Please use the Fully Qualified Domain Name`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`FHIR Database Purge Frequency`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<Integer>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`FHIR database purge frequency in days`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Report Server Name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`<ISSorAIOservername.domain.com>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This is used to configure ES Applications the utilize Reporting Services. Please use the FQDN of the Server with SSRS deployed: ISS or AIO.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Website Name - PES for AIO. Default Web Site for ISS`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Deafault Web Site`}</inlineCode>{` or `}<inlineCode parentName="td">{`PES`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`YES`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This is used for the IDM Web application deployment. If it is an AIO, set this to `}<inlineCode parentName="td">{`PES`}</inlineCode>{`. If this is an 3 box, set this to `}<inlineCode parentName="td">{`Deafault Web Site`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
      </AccordionItem>
      <AccordionItem title="Troubleshooting Deployments" mdxType="AccordionItem">
        <InlineNotification kind="warning" mdxType="InlineNotification">
          <p>{`Ensure the Deployment Profile is created correctly `}<strong parentName="p">{`BEFORE`}</strong>{` proceeding with kicking off the deployment. Deployment Profiles are “stateful” meaning once you START a deployment with a Profile, those values are locked in place, even if you update/fix them.`}</p>
        </InlineNotification>
        <br />
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Issue`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Severity`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Recommended Fix`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Incorrect Parameter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low to High`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`If caught early, correct the parameter in the Profile and re-run the deployment. If software was already deployed, you will need to revert the System. This requires creating a new deployment and installing a new AWS Agent.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`BDAgent Install Error`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Verify the time on the VM(s) is correct. Verify the correct agent exe is being used. Check `}<inlineCode parentName="td">{`C:\\ProgramData\\Amazon\\SSM\\Logs`}</inlineCode>{` for more logs.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Deploy Server Step Fails`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low to High`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Deployment steps can be re-executed if a sub-step fails.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Service Stop Step Fails`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Low`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Re-run step(s) to stop services, if they fail, stop and disable services manually.`}</td>
            </tr>
          </tbody>
        </table>
      </AccordionItem>
    </Accordion>
    <h2>{`Post Installation Configuration`}</h2>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Configuring Bulletin Reports" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Run Internet Explorer as an administrator and navigate to: `}<inlineCode parentName="li">{`http://<ISS_Server>/Reports`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Dispensing System Reports`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Data Sources`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`BulletinReports`}</inlineCode></li>
          <li parentName="ol">{`On the BulletinReports page, complete the following:`}
            <ol parentName="li">
              <li parentName="ol">{`Select Using the following credentials`}</li>
              <li parentName="ol">{`Under Type of credentials, select Windows username and password`}</li>
              <li parentName="ol">{`Enter the username and password: DomainName\\CFNservice account & Password`}</li>
              <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Test Connection`}</inlineCode></li>
              <li parentName="ol">{`Click Apply`}</li>
            </ol>
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Running ETL" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`From the ISS Server, open SQL Server Management Studio (SSMS)`}</li>
          <li parentName="ol">{`Navigate to the SQL Jobs and enable the `}<inlineCode parentName="li">{`Reports`}</inlineCode>{` jobs`}</li>
          <li parentName="ol">{`Right-click SQL Server Agent`}</li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Restart`}</inlineCode></li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Yes`}</inlineCode></li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Job Activity Monitor`}</inlineCode></li>
          <li parentName="ol">{`Locate the `}<inlineCode parentName="li">{`ETL – Dispensing System Server Reports Database`}</inlineCode>{` job`}</li>
          <li parentName="ol">{`Confirm Status is not Executing`}</li>
          <li parentName="ol">{`Verify that the job is completed successfully (This could take a few minutes)`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Verifying Report Schedule Configuration Tool Settings" mdxType="AccordionItem">
        <ol>
          <li parentName="ol">{`Login to the Application server`}</li>
          <li parentName="ol">{`From the desktop, right-click on `}<inlineCode parentName="li">{`Configure Report Composer`}</inlineCode>{` and run as Administrator`}</li>
          <li parentName="ol">{`Navigate to DSR > DSR Configuration`}</li>
          <li parentName="ol">{`Make sure the following parameters are set with the new server values:`}
            <ol parentName="li">
              <li parentName="ol">{`Report Server URL: `}<inlineCode parentName="li">{`http://<ISS FQDN>/ReportServer`}</inlineCode></li>
              <li parentName="ol">{`Report Data Source User Name: `}<inlineCode parentName="li">{`<domain>\\cfnservice`}</inlineCode></li>
              <li parentName="ol">{`Report Data Source Password: `}<inlineCode parentName="li">{`<domain>\\cfnservice`}</inlineCode>{` password`}</li>
              <li parentName="ol">{`Scheduler Server: `}<inlineCode parentName="li">{`<APP Server>`}</inlineCode></li>
            </ol>
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Configuring SQL Server" mdxType="AccordionItem">
        <p>{`Perform the following steps on the Database Server, if it is BD managed, and the ISS Server.`}</p>
        <ol>
          <li parentName="ol">{`Open SQL Server Management Studio`}</li>
          <li parentName="ol">{`Right click on the instance name`}</li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Properties`}</inlineCode></li>
          <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Memory`}</inlineCode></li>
          <li parentName="ol">{`Set `}<inlineCode parentName="li">{`Maximum Memory`}</inlineCode>{` according to latest `}<em parentName="li">{`Pyxis ES Deployment Guide: Ch. 1 - Sec. Virtual Machine Specifications`}</em></li>
          <li parentName="ol">{`Verify that Allow remote connections is `}<inlineCode parentName="li">{`Enabled`}</inlineCode></li>
          <li parentName="ol">{`Under Database Settings, enable `}<inlineCode parentName="li">{`Compress Backup`}</inlineCode></li>
          <li parentName="ol">{`Open Command Prompt as the Administrator`}</li>
          <li parentName="ol">{`Execute the following command where `}<inlineCode parentName="li">{`xxxxx-xxxxx-xxxxx-xxxxx-xxxxx`}</inlineCode>{` is replaced with the appropriate license key:`}</li>
        </ol>
        <pre><code parentName="pre" {...{}}>{`C:\\SQL2016\\Setup.exe /Q /ACTION=editionupgrade /INSTANCENAME=MSSQLSERVER/IAcceptSQLServerLicenseTerms /Indicateprogress /PID=xxxxx-xxxxx-xxxxx-xxxxx-xxxxx
`}</code></pre>
        <ul>
          <li parentName="ul">{`Customer-provided SQL license: Replace `}<inlineCode parentName="li">{`xxxxx-xxxxx-xxxxx-xxxxx-xxxxx`}</inlineCode>{` with the customer provided key`}</li>
          <li parentName="ul">{`BD-provided SQL license: Replace `}<inlineCode parentName="li">{`xxxxx-xxxxx-xxxxx-xxxxx-xxxxx`}</inlineCode>{` with the key that can be obtained on BD EFT site`}</li>
          <li parentName="ul">{`EFT Path: `}
            <pre parentName="li"><code parentName="pre" {...{}}>{`/GCS-Released (R)/CONTROLLED/SQL Server License
`}</code></pre>
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="ES Database Cleanup Tool" mdxType="AccordionItem">
        <p>{`The tool is designed to be used on a customer DSServerOLTP database that is pre 5.0 (SR 1.7.0) and has been upgraded to 1.7.0. This would happen prior to any devices being synced to server database.`}</p>
        <ol>
          <li parentName="ol">{`Login to the Application or AIO Server`}</li>
          <li parentName="ol">{`Copy the official release es-server-data-cleanup.zip file from EFT to and extract the zip file.`}
            <ol parentName="li">
              <li parentName="ol">{`Path:`}
                <pre parentName="li"><code parentName="pre" {...{}}>{`/(Home)>GCS-LCR (R)>Dispensing>MedStation>Domestic>MedStation ES>Server>1.7.x>Tools>10000371607-00 ES DB Cleanup Tool v5.0.0.16
`}</code></pre>
              </li>
            </ol>
          </li>
          <li parentName="ol">{`Open SSMS and connect to the SQL server.`}</li>
          <li parentName="ol">{`Open the extracted es-server_data_cleanup_5_0_1.sql file in SSMS.`}</li>
          <li parentName="ol">{`Execute the SQL script.`}</li>
          <li parentName="ol">{`The script should complete successfully.`}</li>
        </ol>
      </AccordionItem>
      <AccordionItem title="Configuring BD Pyxis ES Platform Services" mdxType="AccordionItem">
        <h4>{`Configuring the Redirect URLs for BD Pyxis Link Web`}</h4>
        <p>{`Perform the following procedure to configure the redirect URL settings for BD Pyxis Link Web on the Identity Server (IDS).`}</p>
        <ol>
          <li parentName="ol">{`Open a web browser on the Application or AIO server`}</li>
          <li parentName="ol">{`In the address box, type the following URL: `}<inlineCode parentName="li">{`https://localhost:11998`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Login`}</inlineCode></li>
          <li parentName="ol">{`Enter the BD user credentials`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Support`}</inlineCode></li>
          <li parentName="ol">{`On the client list, click `}<inlineCode parentName="li">{`BD Pyxis_link_web`}</inlineCode></li>
          <li parentName="ol">{`Enter the applicable redirect URLs:`}
            <ol parentName="li">
              <li parentName="ol">{`In the Post Login Redirect URI text box, enter the following: `}<inlineCode parentName="li">{`https://FQDN:444/pyxislink/?client_id=pyxis_link_web`}</inlineCode></li>
              <li parentName="ol">{`In the Post Logout Redirect URI text box, enter the following: `}<inlineCode parentName="li">{`https://FQDN:444/pyxislink/?client_id=pyxis_link_web`}</inlineCode></li>
            </ol>
          </li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Logout`}</inlineCode></li>
          <li parentName="ol">{`Restart ISS`}</li>
        </ol>
        <h4>{`Configuring BD Pyxis Med Link with Epic SSO (Single Sign On)`}</h4>
        <p>{`Perform the following procedure to configure Substitutable Medical Applications, Reusable Technologies (SMART) on Fast Healthcare Interoperability Resource (FHIR) for EpicTM systems.`}</p>
        <ol>
          <li parentName="ol">{`Open a web browser on the Application or AIO server`}</li>
          <li parentName="ol">{`In the address box, type the following URL: `}<inlineCode parentName="li">{`https://localhost:11998`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Login`}</inlineCode></li>
          <li parentName="ol">{`Enter the BD user credentials`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Support`}</inlineCode></li>
          <li parentName="ol">{`On the client list, click `}<inlineCode parentName="li">{`Epic_Hyperspace`}</inlineCode></li>
          <li parentName="ol">{`Enter the applicable redirect URLs:`}
            <ol parentName="li">
              <li parentName="ol">{`In the Post Login Redirect URI text box, enter the following: `}<inlineCode parentName="li">{`https://FQDN:444/pyxislink/?client_id=epic_hyperspace`}</inlineCode></li>
              <li parentName="ol">{`In the Post Logout Redirect URI text box, enter the following: `}<inlineCode parentName="li">{`https://FQDN:444/pyxislink/?client_id=epic_hyperspace`}</inlineCode></li>
            </ol>
          </li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Configuration`}</inlineCode></li>
          <li parentName="ol">{`In the Epic Issuer URI text box, enter the provided issuer information.
NOTE
The presence of the trailing forward slash must match the Epic configuration. Example: `}<inlineCode parentName="li">{`https://epicserveraddress.hospital.org/api/FHIR/DSTU2/`}</inlineCode></li>
          <li parentName="ol">{`In the Epic Metadata endpoint URI text box, enter the provided metadata endpoint. Example: `}<inlineCode parentName="li">{`https://epicserveraddress.hospital.org/api/FHIR/DSTU2/metadata`}</inlineCode></li>
          <li parentName="ol">{`In the Epic Client ID text box, enter the provided Client ID information.`}
            <ul parentName="li">
              <li parentName="ul">{`Example: `}
                <ul parentName="li">
                  <li parentName="ul">{`Test: e1ebf823-0c71-4df5-ac56-2b0b224d4d4f `}</li>
                  <li parentName="ul">{`Prod: 72fcc143-9930-4169-b6c8-919868775366`}</li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        <InlineNotification mdxType="InlineNotification">
          <p><strong parentName="p">{`NOTE:`}</strong>{` Epic Client IDs will differ between the test and production environments.
If the Epic Issuer was not entered when previously running the config-ids.bat tool, it needs to be executed again to populate this value. See step 5 in the Configuring IIS bindings and certificate configurations.`}</p>
        </InlineNotification>
        <br />
        <ol {...{
          "start": 13
        }}>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Submit`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Logout`}</inlineCode></li>
          <li parentName="ol">{`In the Epic Issuer box, copy and paste the following URL and then change the URL to match the Epic server:
`}<inlineCode parentName="li">{`https://epicserveraddress.hospital.org/api/FHIR/DSTU2/`}</inlineCode></li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`OK`}</inlineCode></li>
          <li parentName="ol">{`If a message about failure to start mobile dock service appears, perform the following steps. Otherwise, continue to Verifying successful installation and configurations`}
            <ol parentName="li">
              <li parentName="ol">{`On the failure message, click `}<inlineCode parentName="li">{`OK`}</inlineCode></li>
              <li parentName="ol">{`Verify that step 3 was not missed and that there are no typos in step 4`}</li>
              <li parentName="ol">{`If the mobile dock service continues to not start properly, escalate following the normal process`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Press any key to close the command prompt window`}</li>
        </ol>
        <h4>{`Configuring IIS`}</h4>
        <ol>
          <li parentName="ol">{`Validate that the customer provided certificates are bound to the PES page on the App Server and default web site on the ISS Server.`}</li>
          <li parentName="ol">{`If the customer has not provided certificate, create a Self-Signed Certificate for the App Server and ISS Server:`}
            <ol parentName="li">
              <li parentName="ol">{`From the App Server launch IIS`}</li>
              <li parentName="ol">{`Select Server Name`}</li>
              <li parentName="ol">{`Select Server Certificates`}</li>
              <li parentName="ol">{`Select Create Self-Signed Certificate`}</li>
              <li parentName="ol">{`Launch a command window as administrator and enter certmgr.msc`}</li>
              <li parentName="ol">{`Navigate to Trusted Root Certification Authorities > Certificates`}</li>
              <li parentName="ol">{`Right Click on the PES site and select Server Certificates.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`For a three-server deployment, import SSL Certificates from the App Server to the ISS Server and the ISS Server to the App Server.`}</li>
          <li parentName="ol">{`For a three-server deployment, add the FQDN of the App Server and FQDN of the ISS Server into trusted sites for both servers.`}</li>
        </ol>
      </AccordionItem>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      